
import React, { useEffect, useState, createContext } from "react";
import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { SEARCH_API } from './../../services/Api/searching';
import PageHeader from "../../components/PageHeader/PageHeader";

let planId = "";
let planPrice = "";
const AddOnModule = () => {
    const Context = createContext('');
    const value = '';
    let userDetail = JSON.parse(localStorage.getItem('setUser'));
    const [countryId, setcountryid] = useState();
    const [plan_id, setPlan_Id] = useState();
    const [availableplanList, setavailablePlanList] = useState([]);
    const [addModuleList, setaddModuleList] = useState([]);
    const [test, setTest] = useState([]);
    const [addIDS, setaddIDS] = useState([])
    const[plan_detail_id,setPlan_detail_id]=useState("")
    const[totalAMT,setTotalAMT]=useState(0)
    const [UpplanPrice, setUpdatePrice] = useState(0);
    const [selectionList, setPlanSelectionList] = useState(0);
    Object.entries(test).map(([key, val]) => {
        if (countryId == 1) {
            planPrice = val?.plan_price_us
        } else {
            planPrice = val?.plan_price_can
        }
    })
    
    const {id} = useParams();
    useEffect(() => {
        setcountryid(userDetail?.userInfo?.country_id);
        PLAN_SELECTION.PlanSelectionList().then((response) => {
            setPlanSelectionList(response.data);
            setTest(response.data.filter((item) => item.plan_id.toString() === planId).map((plan) => {
                return (
                    {
                        plan_price_can: plan.plan_price_can,
                        plan_price_us: plan.plan_price_us
                    }
                );
            }))
        });
        SEARCH_API.getModuleByUser(userDetail?.userInfo?.user_id).then((response) => {
            setavailablePlanList(response.data);
            setPlan_Id(response?.plan_detail_id);
            setPlan_detail_id(response?.plan_detail_id)
        })
    }, []);
   
    const GoProceed = (e) => {
        e.preventDefault();
        let module_id=addModuleList?.map(val=>{
            return val?.module_id
        });
        localStorage.setItem('plan_detail_id',plan_detail_id);
        window.location.href = `#/account/payment-confirmation/${module_id}`
    }

    const topup = (id) => {
        const addonids = [];
        addonids.push(id);
        setaddIDS([...addIDS, addonids]);
        const newModuleList = availableplanList.filter((e, i) => e.module_id === id);
        setaddModuleList(prev => [...prev, newModuleList[0]])
        let onlyPrice = countryId == 2 ? newModuleList[0]?.can_price : newModuleList[0]?.us_price;    
        let final =  parseFloat(totalAMT)+onlyPrice;
        let fadd=final.toFixed(2)
        setTotalAMT(parseFloat(fadd))
        setavailablePlanList(availableplanList.filter((e) => e.module_id !== id));
    }

    const removeAddModule = (id) => {
        setaddModuleList(addModuleList.filter((e) => e.module_id !== id));
        const refill = addModuleList.filter((e) => e.module_id === id)[0];
        setaddIDS(addIDS.filter((e) => e !== id));
        let onlyPrice = countryId == 2 ? refill?.can_price : refill?.us_price;
        let final = parseFloat(totalAMT) - parseFloat(onlyPrice);
        let fprice = final?.toFixed(2)
        setTotalAMT(parseFloat(fprice))
        setavailablePlanList((prev) => [...prev, refill])
    }

    const getModuleAvailableList = () => {
        return Object.entries(availableplanList).map(([key, val]) => (
            // (addIDS.includes(val?.module_id) === false && val?.module_name !== "Oncology" ? 
            (addIDS.includes(val?.module_id) === false ? 
                <div className="col-md-6" key={val?.module_id} id="plan-select">
                    <div class="card mb-4 shadow">
                        <img src={val?.image} id="card-img-top" width={225} height={140} className='card-img-top border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
                        <div class="bg-white mt-3" style={{backgroundColor: 'rgb(249 246 246 / 10%) '}}><h5>{val?.module_name}</h5></div>
                        <div class="">
                            <ul class="list-unstyled mt-3 mb-3">
                                <li>{val?.description}</li>
                            </ul>
                            <h6 class="pricing-card-title mb-4">
                                ${countryId === 2 ? val?.can_price=='NA'?0: val?.can_price: val?.us_price=='NA'?0: val?.us_price} {userDetail?.userInfo?.country_id==2?"CAD":"USD"}
                            </h6>
                            <button type="button" class="btn btn-sm btn-primary text-uppercases" onClick={(e) => topup(val?.module_id)}>Add Module</button>
                            <br /><br />
                        </div>
                    </div>
                </div> 
            : null)
        )) 
    }
    

    const getaddModuleIncludedList = () => {
        return Object.entries(addModuleList).map(([key, val]) => (
            <div className="row">
                <div class="card shadow" id="set-add-on" key={val.module_id}>
                    <img src={val?.image} id="card-img-top" width={225} height={140} className='card-img-top border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
                    <div class="bg-white mt-3" style={{backgroundColor: 'rgb(249 246 246 / 10%)'}}><h5>{val?.module_name}</h5></div>
                    <div class="">
                        <ul class="list-unstyled mt-3 mb-3">
                            <li>{val?.description}</li>
                        </ul>
                        <h6 class="pricing-card-title mb-4">
                        ${countryId === 2 ? val?.can_price=='NA'?0: val?.can_price: val?.us_price=='NA'?0: val?.us_price} {userDetail?.userInfo?.country_id==2?"CAD":"USD"}
                        </h6>
                        <button type="button" class="btn btn-sm btn-danger text-uppercases" onClick={() => removeAddModule(val.module_id)}>Remove</button>
                        <br /><br /> </div>
                </div>
            </div>
        ))
    }

    return (
        <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover">
             <PageHeader  style={{marginTop:'50px', height:'20px',marginTop:'50px'}} 
        bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
        title="Add on modules" 
        description={""}/>
            <br/>
            <div className="container">
                {totalAMT!==0?  <h3 style={{fontFamily:'sans-serif'}}>Total <span style={{fontSize:18}}>${totalAMT} {userDetail?.userInfo?.country_id==2?"CAD":"USD"}</span></h3>:""}
                {/* <div className="row justify-content-sm-center align-items-center offset-top-0"> */}
                   
                    {availableplanList?.length > 0 ?
                        <div className="row justify-content-sm-center align-items-center offset-top-0">
                            <div className="col-lg-6" style={{float:'right'}}>
                                <h2 className="mb-2">Available Modules</h2>
                                <div className="row">
                                    {getModuleAvailableList()}
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-12" style={{float:'left'}}>
                                {addModuleList?.length>0?<h2 className="mb-4">Add On Module</h2>:""}
                                <div className="row ml-2" style={{marginTop:'-40px'}}>
                                    {getaddModuleIncludedList()}
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        : <div className="row">
                            <div className="col-lg-12">
                        <h2 className="">Add On Module</h2>
                        <div className="row" style={{margin:'auto'}}>
                            {getaddModuleIncludedList?.length>=1?<>{getaddModuleIncludedList()}</>:<>{getaddModuleIncludedList()}</>}
                        </div>
                        
                    </div>
                        </div>
                    }
                    <div className="row justify-content-sm-center align-items-center offset-top-40">
                            <div className="col">
                                {totalAMT==0?"":<button className="btn btn-primary" onClick={GoProceed} >Proceed</button>} 
                            </div>
                        </div>
                {/* </div> */}
                {/* <div className="row justify-content-sm-center align-items-center offset-top-20">
                    {addModuleList.length > 0 ?
                        <div className="col-lg-6">
                            <h2 className="">Add On Module</h2>
                            <div className="row">
                                {getaddModuleIncludedList()}
                            </div>
                            <div className="row justify-content-sm-center align-items-center offset-top-40">
                                <div className="col">
                                    {totalAMT==0?"":<button className="btn btn-primary" onClick={GoProceed} >Proceed</button>} 
                                </div>
                            </div>
                        </div> : ""}
                </div> */}
            </div>
        </section>
    );
};
export default AddOnModule