
import "./../node_modules/bootstrap/scss/bootstrap.scss"
import './App.scss';

import React, { createContext, useEffect, useReducer } from 'react';
import { Routes, Route } from "react-router-dom";
import Preloader from "./components/Preloader";

import Header from './components/common/Header/HeaderMain'
import Footer from './components/common/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Calculator from './pages/Calculators';
import News from './pages/News';
import Contact from './pages/Contact';
import SignIn from './pages/SignIn';
import Logout from './pages/Logout';
import Register from './pages/Register';
import SingleNews from "./pages/News/SingleNews";
import PlanSelection from "./pages/PlanSelection";
import ModuleSelection from "./pages/ModuleSelection";
import BillingInfo from "./pages/BillingInfo";
import Confirmation from "./pages/Confirmation";
import Purchased from "./pages/Purchased/Purchased";
import Search from "./pages/Search/Search";
import DrugDetails from "./pages/DrugDetails/DrugDetails";
import DrugList from "./pages/DrugList";
import AccountHome from "./pages/Account/Home";
import Topic from "./pages/Account/Topic";
import DrugDetailOption from "./pages/Account/DrugDetailOption";

import BodyMassIndexCalc from "./pages/Calculators/BodyMassIndexCalc/BodyMassIndexCalc";
import SegmentalCalculator from "./pages/Calculators/SegmentalCalc/SegmentalCalc";
import CalorieReqCalc from "./pages/Calculators/CalorieReqCalc/CalorieReqCalc";
import IdealBodyWeightCalc from "./pages/Calculators/IdealBodyWeightCalc/IdealBodyWeightCalc";
import EGFRCalculator from "./pages/Calculators/EGFRCalculator/EGFRCalculator";
import Navigation from "./components/common/Navigation";
import MySearch from "./pages/Search/mySearch";
import MySearchDetails from "./pages/Search/mySearchDetails";
import PaymentHistory from "./pages/Account/PaymentHistory";
import AccountInformation from './pages/Account/AccountInformation';
import BodySurfaceArea from './pages/Calculators/BodySurfaceArea';
import MyTables from './pages/Account/MyTables';
import RequireAuth from "./services/_helpers/ProtectRoute";
import AddOnModule from "./pages/Account/addOnModule";
import MyConfirmation from "./pages/Confirmation/MyConfirmation";
import ModulePurchased from "./pages/Purchased/ModulePurchage";
import ForgotPassword from "./pages/SignIn/forgot-password";
import ChangesPassword from "./pages/SignIn/changes-password";
import PageNotFound from "./components/common/page-not-found";
import AlcoholInt from "./pages/Tables/Alcohol-Interactions";
import Gluton from "./pages/Tables/Food_Composition/Gluten";
import Caffeine from "./pages/Tables/Food_Composition/Caffeine";
import Osmolalities from "./pages/Tables/Food_Composition/Osmolalities";
import Oxalate from "./pages/Tables/Food_Composition/Oxalate";
import Phytate from "./pages/Tables/Food_Composition/Phytate";
import PressorAgents from "./pages/Tables/Food_Composition/Pressor-Agents";
import PH from "./pages/Tables/Food_Composition/pH";
import MagnesiumSources from "./pages/Tables/Nutrient_Sources/Magnesium-Sources";
import PotassiumSources from "./pages/Tables/Nutrient_Sources/Potassium-Sources";
import VitaminKSources from "./pages/Tables/Nutrient_Sources/Vitamin-K-Sources";
import Grapefruit from "./pages/Tables/Grapefruit Interactions";
import Usinsuline from "./pages/Tables/insuline/Usinsuline";
import Canadainsuline from "./pages/Tables/insuline/Canadainsuline";
import InsulinIndex from "./pages/Tables/insuline/InsulinIndex";
import LabValuIndex from "./pages/Tables/Lab-Values";
import Causal from "./components/cau";
import NewsDetail from "./pages/News/NewsDetail";
import ViewCustomization from "./pages/Account/ViewCustomization";
import GuestDrugDetails from "./pages/DrugDetails/GuestDrugDetails";
import UsTOC from "./components/TermAndPolicy/UsTOC";
import CanadaTOC from "./components/TermAndPolicy/CanadaTOC";
import CanadaFrenchTOC from "./components/TermAndPolicy/CanadaFrenchTOC";
import Desclimer from "./components/TermAndPolicy/Desclimer";
import PrivacyPolicy from "./components/TermAndPolicy/PrivacyPolicy";
import UsersGuide from "./components/TermAndPolicy/UserGuide";
import {checklogout} from "./services/authcheck";

export const UserContext = createContext();
import io from "socket.io-client";
import MDSHighRisk from "./pages/Tables/Mds-highrisk-Interactions";
const socket = io('https://api.rasovaisaha.com:5203');
socket.on('connect', () => {
  console.log('Connected to server on port 5203');
});

function App() {
   
useEffect(()=>{  
  socket.on('message', (event) => {
    console.log(event)
    try {
    
    }
    catch (e) {

    }
  });
},[]);
setInterval(checklogout,5000);
  return (<> 
    <div className="page text-center">
      
      <Header className="main-header-app"/>
    
      {/* <Preloader/>  */}
      <Routes>
       {/* UNPROTECTTED ROUTES   UsTOC*/}
       <Route path="/news/news-details/:news_id" element={<NewsDetail />} />
       <Route path="/" element={<Home />} />
       <Route path="/us-toc" element={<UsTOC />} />
       <Route path="/can-toc" element={<CanadaTOC />} />
       <Route path="/can-f-toc" element={<CanadaFrenchTOC />} />
       <Route path="/disclaimer" element={<Desclimer />} />
       <Route path="/privacy" element={<PrivacyPolicy />} />
       <Route path='*' exact={true}  element={<PageNotFound/>} />
       <Route path="/about" element={ <About/>} />
       <Route path="/calculator" element={<Calculator/>} />
       <Route path="/calculator/body-mass-index" element={<BodyMassIndexCalc/>} />
       <Route path="/calculator/segmental-weights" element={<SegmentalCalculator />} />
       <Route path="/signin" element={<SignIn/>} />
       <Route path="/register" element={<Register/>} />
       <Route path="/single-news" element={<SingleNews/>} />
       <Route path="/news" element={<News/>} />
       <Route path="/contact" element={<Contact/>} />
       <Route path="/forgot-password" element={<ForgotPassword/>}/>
       <Route path="/quest/drug-details" element={<GuestDrugDetails/>}/>
       <Route path="/change-password" element={<RequireAuth><ChangesPassword/></RequireAuth>}/>
       {/* PROTECTED ROUTES  */}
       <Route path="/calculator/calorie-requirement" element={<RequireAuth><CalorieReqCalc/></RequireAuth>} />
       <Route path="/calculator/ideal-body-weight" element={<RequireAuth><IdealBodyWeightCalc/></RequireAuth>} />

       {/* <Route path="/logout" element={<Logout/>} /> */}
       <Route path="/calculator/eGFR" element={<RequireAuth><EGFRCalculator /></RequireAuth>} />
       <Route path="/calculator/body-surface-area" element={<RequireAuth><BodySurfaceArea/></RequireAuth>} />
       <Route path="/plan-selection" element={<RequireAuth><PlanSelection/></RequireAuth>} />
       <Route path="/module-selection/:id" element={<RequireAuth><ModuleSelection/></RequireAuth>} />
       <Route path="/billing-information" element={<RequireAuth><BillingInfo/></RequireAuth>} />
       <Route path="/confirmation" element={<RequireAuth><Confirmation/></RequireAuth>} />
       <Route path="/purchased" element={<RequireAuth><Purchased/></RequireAuth>} />
       <Route path="/search" element={<RequireAuth><Search/></RequireAuth>} />
       
       {/* <Route path="/drug-details" element={<DrugDetails/>} /> */}
       <Route path="/account/drug-list" element={<RequireAuth><DrugList/></RequireAuth>} />

       <Route path="/my-table" element={<RequireAuth><MyTables/></RequireAuth>} />
       <Route path="/account" element={<AccountHome/>} />
       <Route path="/account/topics" element={<RequireAuth><Topic/></RequireAuth>} />
       <Route path="/account/drug-detail-options" element={<RequireAuth><DrugDetailOption/></RequireAuth>} />
       <Route path="/account/search" element={<RequireAuth><MySearch/></RequireAuth>} />
       <Route path="/account/drug-details" element={<RequireAuth><DrugDetails/></RequireAuth>} />
       <Route path="/account/search-details" element={<RequireAuth><MySearchDetails/></RequireAuth>} />
       <Route path="/account/payment-history" element={<RequireAuth><PaymentHistory/></RequireAuth>} />
       <Route path="/account/my-account" element={<RequireAuth><AccountInformation/></RequireAuth>} />
       <Route path="/account/add-on-modules/:id" element={<RequireAuth><AddOnModule/></RequireAuth>} />
       <Route path="/account/payment-confirmation/:id" element={<RequireAuth><MyConfirmation/></RequireAuth>} />
       <Route path="/account/module-purchage-confirmation" element={<RequireAuth><ModulePurchased/></RequireAuth>} />
       <Route path="/account/view_customization" element={<RequireAuth><ViewCustomization/></RequireAuth>} />
       
       <Route path="/my-table/alcohol-interactions" element={<RequireAuth><AlcoholInt/></RequireAuth>} />

       <Route path="/my-table/glutan-table" element={<RequireAuth><Gluton/></RequireAuth>} />
       <Route path="/my-table/Caffeine-table" element={<RequireAuth><Caffeine/></RequireAuth>} />
       <Route path="/my-table/Osmolalities-table" element={<RequireAuth><Osmolalities/></RequireAuth>} />
       <Route path="/my-table/Oxalate-table" element={<RequireAuth><Oxalate/></RequireAuth>} />
       <Route path="/my-table/Phytate-table" element={<RequireAuth><Phytate/></RequireAuth>} />
       <Route path="/my-table/PressorAgents-table" element={<RequireAuth><PressorAgents/></RequireAuth>} />
       <Route path="/my-table/pH-table" element={<RequireAuth><PH/></RequireAuth>} />

       <Route path="/my-table/MagnesiumSources-table" element={<RequireAuth><MagnesiumSources/></RequireAuth>} />
       <Route path="/my-table/PotassiumSources-table" element={<RequireAuth><PotassiumSources/></RequireAuth>} />
       <Route path="/my-table/VitaminKSources-table" element={<RequireAuth><VitaminKSources/></RequireAuth>} />
       <Route path="/my-table/Grapefruit-interactions" element={<RequireAuth><Grapefruit/></RequireAuth>} />
       <Route path="/my-table/MDSHighRisk-interactions" element={<RequireAuth><MDSHighRisk /></RequireAuth>} />
       {/* <Route path="/my-table/Usinsuline-table" element={<RequireAuth><Usinsuline/></RequireAuth>} />
       <Route path="/my-table/Canadainsuline-table" element={<RequireAuth><Canadainsuline/></RequireAuth>} /> */}
      <Route path="/my-table/insuline-table" element={<RequireAuth><InsulinIndex/></RequireAuth>} />
      <Route path="/my-table/lab-value" element={<RequireAuth><LabValuIndex/></RequireAuth>} />
      <Route path="/users-guide" element={<RequireAuth><UsersGuide/></RequireAuth>} />

      
     </Routes>
      <Footer />
    </div>
    
    {/* <Navigation/> */}

    </>
  );
}

export default App;
