import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { TableAPI } from "../../services/Api/TableApi";
import ReactModal from 'react-modal';
import '../../../src/regular.css';
import Table from 'react-bootstrap/Table';
import mdsHighRisk_img from "../../../src/assets/images/banner/mdsHighRiskBanner.jpg";
import BackIcon from '../../assets/images/previousBtn.png'

const MDSHighRisk = () => {
    const [HighRiskDrug, setHighRiskDrug] = useState([]);
    const dropVal = [
        { category: "A – Antipsychotics", mappedValues: ["antipsychotic", "antipsychotic, first generation", "antipsychotic, second generation", "antipsychotic, third generation"] },
        { category: "B – Anti-anxiety", mappedValues: ["anxiolytic"] },
        { category: "C – Antidepressant", mappedValues: ["antidepressant"] },
        { category: "D – Hypnotic", mappedValues: ["hypnotic", "sedative"] },
        { category: "E – Anticoagulant", mappedValues: ["anticoagulant"] },
        { category: "F – Antibiotic", mappedValues: ["antibiotic"] },
        { category: "G – Diuretic", mappedValues: ["diuretic"] },
        { category: "H – Opioid", mappedValues: ["opioid"] },
        { category: "I – Antiplatelet", mappedValues: ["antiplatelet"] },
        { category: "J – Hypoglycemic", mappedValues: ["antidiabetic"] }
    ];


    const [modalIsOpen, setIsOpen] = useState(false);
    const [Generic, setGeneric] = useState("");
    const [Text, setText] = useState("");
    const [GenericId, setGenericID] = useState("");
    const [getHeight, setHeight] = useState("");
    const [modalStyle, setModalStyle] = useState({
        left: '0%',
        width: '100%',
    });

    const handleChange = (e) => {
        if (e.target.name === "Interaction1") {
            if (e.target.value == "0") {
                setHighRiskDrug([]);
                return;
            }

            // Extract the selected mappedValues array from dropVal
            const selectedCategory = dropVal.find(val => val.category === e.target.value);
            const therapeuticClasses = selectedCategory ? selectedCategory.mappedValues : [];

            // Initialize an empty array to collect all responses
            let allResults = [];

            // Function to fetch data for each therapeutic class
            const fetchDrugsByClass = (therapeuticClass) => {
                const requestObj = {
                    therapeutic_class: therapeuticClass,
                };

                return TableAPI.getDrugsByTherapeuticClassApi(requestObj)
                    .then((res) => {
                        if (res.status == 200 && res.data) {
                            allResults = [...allResults, ...res.data];
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching drugs:", error);
                    });
            };

            // Fetch results for all therapeutic classes associated with the selected category
            Promise.all(therapeuticClasses.map(fetchDrugsByClass))
                .then(() => {
                    if (allResults.length > 0) {
                        // Sort allResults alphabetically by GenericName
                        allResults.sort((a, b) => a.GenericName.localeCompare(b.GenericName));
                        setHighRiskDrug(allResults);
                    } else {
                        setHighRiskDrug([]);
                    }
                });
        }
    };



    useEffect(() => {
        const updateModalStyle = () => {
            if (window.innerWidth > 768) {
                setModalStyle({
                    left: '35%',
                    width: '32%',
                });
            } else {
                setModalStyle({
                    left: '0%',
                    width: '100%',
                });
            }
        };

        window.addEventListener('resize', updateModalStyle);
        updateModalStyle();

        return () => {
            window.removeEventListener('resize', updateModalStyle);
        };
    }, []);

    const result = HighRiskDrug?.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 3);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    const ParentDiv = result?.length >= 20 ? { height: "600px", overflowY: "auto" } : { overflowY: "auto" };





    return (
        <>       
            <div style={{ textAlign: 'initial' }}>
                <section className="section mdsHighRisk-bg section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left"  data-parallax-img={mdsHighRisk_img}>
                    <div className="parallax-content" id="new-table">
                        <div className="bg-overlay-black">
                            <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                                <div className='d-flex d-lg-block'>
                                    <img
                                        src={BackIcon}
                                        alt="Back"
                                        className="d-lg-none d-md-block"
                                        onClick={() => { window.location.href = '/#/' }}
                                        style={{
                                            position: 'absolute',
                                            top: '101px',
                                            left: '10px',
                                            width: '6%',
                                            height: '19%',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </div>
                                <div className="d-none d-lg-block">
                                    <h1 className='mt-4'>MDS&nbsp;High&nbsp;Risk&nbsp;Drugs</h1>
                                </div>
                                <div className="d-lg-none">
                                    <h5 className='d-flex justify-content-center' style={{ marginTop: '127px' }}>MDS&nbsp;High&nbsp;Risk&nbsp;Drugs</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container mt-1">
                    <br />
                    <div className="row">
                        <p style={{ fontWeight: '400px', fontSize: 14, color: '#484343' }}>
                            The Minimum Data Set (MDS) is a powerful tool for implementing standardized assessment and for facilitating care management in nursing homes and non-critical access hospital swing beds.The <a href="https://www.cms.gov/files/document/draft-mds30-nc-item-set-v11811-oct2023.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#15678f', textDecoration: 'underline' }}>MDS (current version 3.0, October 2023) high-risk drug list</a> supports collaboration with facility leadership and the interdisciplinary team to establish a process of review and monitoring of high-risk medications.
                        </p>
                        <p style={{ fontWeight: '400px', fontSize: 14, color: '#484343' }}>
                            These high-risk drug classes, identified by <a href="https://www.cms.gov" target="_blank" rel="noopener noreferrer" style={{ color: '#15678f', textDecoration: 'underline' }}>Centers for Medicare & Medicaid Services (CMS)</a> require staff education to recognize adverse effects that could potentially adversely impact a resident’s health, quality of life, and safety. A review of documentation to ensure there is a clear indication when a high-risk medication is in use and monitoring for efficacy and adverse effects is completed. If a gap is noted, a plan for improvement should be put in place and monitored. The interdisciplinary team needs to identify high-risk medications in use at a facility and ensure staff, residents and their representatives understand the reasons for use and monitor for adverse effects. This process is tracked and monitored as part of the facility’s Quality Assurance/Performance Improvements (QAPI) program.
                        </p>
                        <div style={{ fontSize: '12px', color: '#666' }}>
                            <div>Reference: Centers for Medicare & Medicaid Services</div>
                            <span>Minimum Data Set 3.0 Section N0415. High-Risk Drug Classes: Use and Indication</span>
                            <p>
                                <a href="https://www.cms.gov/files/document/draft-mds30-nc-item-set-v11811-oct2023.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#15678f', textDecoration: 'underline' }}>
                                    MDS 3.0 Nursing Home Comprehensive (NC) Item Set
                                </a>
                            </p>
                        </div>
                    </div>



                    <div className="row text-center">
                        <div className="col-lg-8">
                            <select className="form-control col-lg-6" name="Interaction1" onChange={handleChange}>
                                <option value="0" selected>--Select High-risk Drug--</option>
                                {dropVal.map((val) => (
                                    <option value={val.mappedValue} key={val.mappedValue}>{val.category}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4"></div>
                        <br />
                    </div>
                    <br />

                    <div>
                        {HighRiskDrug?.length ?
                            <div className="row" style={ParentDiv}>
                                <Table striped bordered hover style={{ tableLayout: 'fixed', width: '100%' }}>
                                    {result?.map((arr, index) => (
                                        <tr key={index}>
                                            {arr.map((itemVal, key) => (
                                                <td key={key}>
                                                    <a id="Gen-heading-hover" href="javascript:void(0)" onClick={() => {
                                                        window.open(`#/account/drug-details?generic_id=${itemVal?.generic_id}`, '_blank');
                                                    }}>
                                                        <p style={{ fontSize: "16px", fontFamily: "Open Sans, Helvetica, Arial, sans-serif !important" }}>
                                                            {itemVal?.GenericName.trim()}
                                                        </p>
                                                    </a>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </Table>

                            </div>
                            :
                            <center style={{ color: "red", fontSize: "18px" }}>No Record Found.</center>
                        }
                    </div>
                </div>
                <br />
            </div>
        </>
    );
}

export default MDSHighRisk;
