import React, { useState } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import Table from 'react-bootstrap/Table';

const LabvaluesCanada = () => {


   const right = {
      borderRight: 0
   }

   // const vertical_scrollable = {
   //         display: "block",
   //         height: "600px",
   //         overflowY: "auto",
   //         overflowX: "scroll",
   //          // widtd: "100%",
   //         widtd: '50%',
   //          margin: 'auto', marginTop: 20
   //         }
   const vartest = 'AM: <26 <br/>Midnight: <2.2'
   var data = 'Normal: <5.7% Prediabetes: 5.7-6.4% Diabetes: >6.5%'
   var abc = 'Normal: <30 Microalbuminuria: 30-299 Macroalbuminuria: ≥300 '


   const [bools, setBools] = useState(false);
   const [heights, setdeights] = useState(600)

   function myFunction() {
      // Declare variables
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("myTable");
      tr = table.getElementsByTagName("tr");

      // Loop tdrough all table rows, and hide tdose who don't match tde search query
      for (i = 0; i < tr.lengtd; i++) {
         td = tr[i].getElementsByTagName("td")[0];
         if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
               tr[i].style.display = "";
            } else {
               tr[i].style.display = "none";
            }
         }
      }
   }

   $(document).ready(function () {
      $(".search").keyup(function () {
         var searchTerm = $(".search").val();
         var listItem = $('.results tbody').children('tr');
         var searchSplit = searchTerm.replace(/ /g, "'):containsi('")

         $.extend($.expr[':'], {
            'containsi': function (elem, i, match, array) {
               return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
            }
         });

         $(".results tbody tr").not(":containsi('" + searchSplit + "')").each(function (e) {
            $(this).attr('visible', 'false');
         });

         $(".results tbody tr:containsi('" + searchSplit + "')").each(function (e) {
            $(this).attr('visible', 'true');
         });

         var jobCount = $('.results tbody tr[visible="true"]').length;
         $('.counter').text(jobCount + ' item');

         if (jobCount == '0') { $('.no-result').show(); }
         else { $('.no-result').hide(); }
      });
   });




   return (
      <div style={{ textAlign: 'initial' }}>
         {/* <PageHeaderTable style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                                bgimg="https://www.trutdaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                                title="Lab Parameters Table (Canada)"
                                description={""} /> */}

         <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
            bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
            title="Lab Parameters Table (Canada)" />
        
        
         <div className="container mt-4">
            <div className="row">
               {/* <div className="col-md-4 input-group">
                                                <button id='static-search'><i className="fa fa-search" aria-hidden="true"></i></button> <input type='text' placeholder="Search.." onChange={myFunction} id="myInput" />
                                        </div> */}
               <div className="form-group pull-left">
                  <input type="text" className="search form-control ml-3" placeholder="Search...." />
               </div>
            </div>

            <div>
               <Table striped bordered hover id="table_lab" className="results">
                  <thead className="header">
                     <tr>
                        <td ><b>&nbsp;Lab&nbsp;Parameter<br />&nbsp;(short&nbsp;name)&nbsp;</b></td>
                        <td > <b>&nbsp;Lab&nbsp;Parameter<br />&nbsp;(full&nbsp;name)&nbsp;</b></td>
                        <td > <b>&nbsp;Reference&nbsp;<br />&nbsp;Interval&nbsp;(SI)&nbsp;</b></td>
                        <td ><b>&nbsp;SI&nbsp;<br />&nbsp;units&nbsp;</b></td>
                        <td > <b>&nbsp;Increased&nbsp;by&nbsp;</b></td>
                        <td > <b>&nbsp;Decreased&nbsp;by&nbsp;</b></td>

                     </tr>
                  </thead>
                  <tbody>
                     {/* <Table style={vertical_scrollable}   id="myTable"> */}

                     <tr  >
                        <td><b>ACT</b></td>
                        <td>Activated clotting time</td>
                        {/* <td>Anticoagulant:&nbsp;therapeutic
                           <br />range:&nbsp;150-210</td> */}
                        <td>Normal: 70-120<br />
                           Anticoagulant therapeutic <br />range: 150-210</td>
                        <td>seconds</td>
                        <td>Cirrhosis, clotting factor deficiencies, heparin/warfarin administration,<br />lupus inhibitor<br />
                           false ▲: heparin contamination</td>
                        <td>Thrombosis</td>
                     </tr>

                     <tr>
                        <td><b>aPTT</b></td>
                        <td>Activated partial thromboplastin time</td>
                        <td>20-35</td>
                        <td>seconds</td>
                        <td>Inhibitors of factors (VIII, IX, XI, XII), intrinsic pathway factor deficiency <br />(VIII, IX, XI, XII), prekallikrein (rare)
                           <br />false ▲: extraneous heparin </td>
                        <td>▲ clotting factor VIII
                           <br />false ▼: tissue thromboplastin contamination</td>
                     </tr>
                     <tr   >
                        <td style={{ right }}><b>ACTH</b></td>
                        <td>Adrenocorticotropic hormone</td>
                        <td>AM: &#60;26<br />
                           Midnight: &#60;2.2</td>
                        <td>pmol/L</td>
                        <td>Addison's disease, Cushing's disease, ectopic ACTH producing tumor, multiple endocrine neoplasia (MEN)</td>
                        <td>Adrenal adenoma, adrenal tumor, Cushing's syndrome, exogenous steroid administration, hypopituitarism</td>
                     </tr>
                     <tr >
                        <td><b>ALT</b></td>
                        <td>Alanine aminotransferase</td>
                        <td>Male: 13-40 <br />Female:10-28</td>
                        <td>U/L</td>
                        <td>Cholestasis, cirrhosis, hepatic necrosis, trauma to striated muscle, viral hepatitis</td>
                        <td>Advanced age</td>
                     </tr>

                     <tr  >
                        <td><b>Albumin</b></td>
                        <td>Albumin (serum)</td>
                        <td>35-50</td>
                        <td>g/L</td>
                        <td>Acute dehydration</td>
                        <td>Chronic hepatic disease, edema/ascites, Inflammation (acute & chronic), malnutrition, renal disease</td>
                     </tr>
                     <tr>
                        <td><b>Albumin (urine)</b></td>
                        <td>Albumin (urine)</td>
                        <td>24 hour urine
                           Normal:&nbsp;&#60;30<br />
                           Microalbuminuria:&nbsp;30-299<br />
                           Macroalbuminuria:&nbsp;≥300 <br />
                           Random urine<br />
                           Normal:&nbsp;&#60;20</td>
                        <td>mg/24 hr<br />


                           mg/L</td>
                        <td>Atherosclerosis, diabetes mellitus, hypertension, renal disease (nephrotic syndrome)</td>
                        <td>&nbsp;</td>
                     </tr>
                     <tr  >
                        <td><b>Aldosterone</b></td>
                        <td>Aldosterone</td>
                        <td>Supine: 0.08-0.44<br />
                           Upright: 0.19-0.83</td>
                        <td>nmol/L</td>
                        <td>Primary aldosteronism: Conn syndrome
                           Secondary aldosteronism: CHF, cirrhosis, pre-eclampsia,  renal disease
                           <br />false ▲: exercise, natural licorice intake (glycyrrhizin)</td>
                        <td>Addison's disease, congenital adrenal hyperplasia, Cushing's syndrome</td>
                     </tr>

                     <tr  >
                        <td><b>AlkP</b></td>
                        <td>Alkaline phosphatase</td>
                        <td>Male:40-130<br />
                           Female:35-105</td>
                        <td>U/L</td>
                        <td>Biliary cirrhosis, hepatic disease, metastatic tumor of the bone, Paget disease (▲ osteoblast activity), pregnancy</td>
                        <td> Hypophosphatasia, hypophosphatemia, hypothyroidism, malnutrition, pernicious anemia</td>
                     </tr>
                     <tr>
                        <td><b>Ammonia</b></td>
                        <td>Ammonia (NH3)</td>
                        <td>11-32</td>
                        <td>umol/L</td>
                        <td>Hepatic failure, renal disease, Reye syndrome, urea cycle disorders
                           <br />false ▲: hemolysis, smoking
                           excessive tourniquet usage during phlebotomy,  improper sample storage, delayed centrifugation and/or delayed analysis</td>
                        <td>Essential hypertension, hyperornithinemia, malignant hypertension</td>
                     </tr>
                     <tr>
                        <td><b>Anion gap</b></td>
                        <td>Anion gap</td>
                        <td>7-15</td>
                        <td>mmol/L</td>
                        <td>Lactic acidosis, ketoacidosis (diabetic & alcoholic), renal failure, starvation
                           <br />false ▲: hyperlipidemia, refer to specific electrolytes used in calculation</td>
                        <td>Chronic vomiting, excessive alkali ingestion, hypoproteinemia, hyperaldosteronism, multiple myeloma
                           <br />false▼: refer to specific electrolytes used in calculation</td>
                     </tr>


                     <tr >
                        <td><b>Anti factor Xa</b></td>
                        <td>Anti factor Xa</td>
                        <td>Low molecular weight<br />heparin: 0.5-1.2<br />
                           Unfractionated <br />heparin: 0.3-0.7</td>
                        <td>IU/mL</td>
                        <td>Hypertriglyceridemia, renal impairment </td>
                        <td>Anti-thrombin deficiency, infection, inflammation, malignancy, obesity
                           <br />false ▼: delayed sample analysis, gross hemolysis, ▲ platelets</td>
                     </tr>


                     <tr >
                        <td><b>ANA</b></td>
                        <td>Anti-nuclear antibody</td>
                        <td>Normal: &#60;1:80 titre<br />
                           Positive: &#62;1:80 titre<br />

                           Positive results indicate pattern(s) identified</td>
                        <td></td>
                        <td>Rheumatoid arthritis (potential positive patterns: centromere, nuclear dots, speckled)<br />
                           scleroderma (potential positive patterns: nuclear envelope)<br />
                           Sjogren’s syndrome (potential positive patterns: centromere, speckled, nuclear dots)<br />
                           systemic lupus erythematosus (potential positive patterns: homogeneous, nucleolar, speckled)<br />
                           systemic sclerosis (potential positive patterns: centromere, nucleolar, speckled)</td>
                        <td></td>
                     </tr>


                     <tr >
                        <td><b>AT-III</b></td>
                        <td>Antithrombin III</td>
                        <td>210-300</td>
                        <td>mg/L</td>
                        <td>Myocardial infarction</td>
                        <td>Hepatic disease, hereditary deficiency, proteinuria, thrombosis</td>
                     </tr>


                     <tr >
                        <td><b>ApoB</b></td>
                        <td>Apolipoprotein B</td>
                        <td>Desirable: &#60;1 g/L<br />
                           High risk: &#62;1.2 g/L</td>
                        <td>g/L</td>
                        <td>Coronary heart disease, diabetes mellitus, familial combined hyperlipidemia, familial hypercholesterolemia, hyperapobetalipoproteinemia (normal LDL with elevated apoB)</td>
                        <td>Homozygous hypobetalipoproteinemia, hyperthyroidism, intestinal malabsorption, malnutrition</td>
                     </tr>

                     <tr >
                        <td><b>AST</b></td>
                        <td>Aspartate aminotransferase</td>
                        <td>5-34</td>
                        <td>U/L</td>
                        <td>Cirrhosis, hepatic cancer hepatic necrosis, muscular dystrophy, viral hepatitis</td>
                        <td>Beriberi, diabetic ketoacidsosis, renal disease</td>
                     </tr>

                     <tr >
                        <td><b>Basophils</b></td>
                        <td>Basophils</td>
                        <td>0.015-0.05</td>
                        <td>x10<sup>9</sup>&nbsp;cells/mm<sup>3</sup>
                           &nbsp; (cells/uL)</td>
                        <td>Leukemia, myelofibrosis, polycythemia vera</td>
                        <td>Acute allergic reactions, hyperthyroidism, stress reactions</td>
                     </tr>

                     <tr >
                        <td><b>Bicarbonate</b></td>
                        <td>bicarbonate (HCO3)</td>
                        <td>Arterial: 21-28<br />
                           Venous: 22-29</td>
                        <td>mmol/L</td>
                        <td>Compensated respiratory acidosis, metabolic alkalosis</td>
                        <td>Compensated respiratory alkalosis, metabolic acidosis</td>
                     </tr>

                     <tr >
                        <td><b>Dbil</b></td>
                        <td>Bilirubin (direct)</td>
                        <td>&#60;3.4</td>
                        <td>umol/L</td>
                        <td>Cholestasis, Dubin-Johnson syndrome, extrahepatic or intrahepatic biliary obstruction, hepatocellular damage, Rotor's syndrome</td>
                        <td>false ▼: sample not protected from light</td>
                     </tr>
                     <tr >
                        <td><b>Tbil</b></td>
                        <td>Bilirubin (total)</td>
                        <td>5-21</td>
                        <td>umol/L</td>
                        <td>Biliary tree obstruction, Crigler-Najjar syndrome, Gilbert syndrome, hemolytic diseases, hepatocellular damage, neonatal jaundice</td>
                        <td>false ▼: sample not protected from light</td>
                     </tr>
                     <tr >
                        <td><b>BNP</b></td>
                        <td>B-type natriuretic peptide</td>
                        <td>Normal or compensated <br />CHF:&#60;200<br />
                           Moderate <br />CHF: 200-400<br />
                           Moderate to Severe <br />CHF: &#62;400 </td>
                        <td>ng/L</td>
                        <td>CHF, heart transplant rejection, myocardial infarction, systemic hypertension</td>
                        <td></td>
                     </tr>




                     <tr >
                        <td><b>Calcium</b></td>
                        <td>Calcium (Ca)</td>
                        <td>2.15-2.5</td>
                        <td>mmol/L</td>
                        <td>Hyperparathyroidism (primary and tertiary), malignant disease (with or without bone invovlement), multiple myeloma, Paget's disease of bone, prolonged immobilization</td>
                        <td>Chronic renal failure, hypoparathyroidism (congenital, idiopathic or surgical), lymphoma/leukemia (tumor lysis syndrome), magnesium deficiency, rickets, vitamin D deficiency</td>
                     </tr>

                     <tr >
                        <td><b>CD4 count</b></td>
                        <td>CD4 lymphocyte count</td>
                        <td>0.64-1.18</td>
                        <td>x10<sup>9</sup>&nbsp;cells/L&nbsp;
                        </td>
                        <td>Autoimmune disease, HIV, immunosuppressive therapy, infections, lymphoma</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Chloride</b></td>
                        <td>Chloride (Cl)</td>
                        <td>98-107</td>
                        <td>mmol/L</td>
                        <td>Acute renal failure, dehydration, diabetes insipidus, renal tubular acidosis, respiratory alkalosis
                           <br />false ▲: salicylate intake</td>
                        <td>Addisonian crisis, excessive sweating, gastric suction, prolonged vomiting, salt-losing nephritis</td>
                     </tr>

                     <tr >
                        <td><b>Tchol</b></td>
                        <td>Cholesterol (total)</td>
                        <td>Desirable: &#60;5.18<br />
                           Borderline high: 5.18-6.19<br />
                           High: &#62;6.19</td>
                        <td>mmol/L</td>
                        <td>Defective apoB-100, familial combined hyperlipidemia, familial dysbetalipoproteinemia (type III), famililal hypercholesterolemia, polygenic hypercholesterolemia</td>
                        <td>Abetalipoporteinemia, hepatocellular necrosis, hypo-alpha-lipoporteinemias (eg. Tangier disease), malignancy
                           <br />false ▼: recumbent position during sample collection</td>
                     </tr>

                     <tr >
                        <td><b>Factor VIII</b></td>
                        <td>Clotting factor VIII</td>
                        <td>0.5-1.5</td>
                        <td>IU/mL</td>
                        <td>Chronic inflammation, malignancy, hepatic disease, pregnancy, surgery</td>
                        <td>Hemophilia A, von Willebrand disease</td>
                     </tr>

                     <tr >
                        <td><b>Cortisol</b></td>
                        <td>Cortisol</td>
                        <td>AM: 138-635<br />
                           PM: 83-441</td>
                        <td>nmol/L</td>
                        <td>Adrenal ademona, cancer, Cushing's disease, Cushing's syndrome, ectopic ACTH producing tumors</td>
                        <td>Addison's disease, congential adrenal hyperplasia, hypopituitarism, hypothyroidism</td>
                     </tr>

                     <tr >
                        <td><b>CRP</b></td>
                        <td>C-reactive protein</td>
                        <td>&#60;10</td>
                        <td>mg/L</td>
                        <td>Autoimmune disease, inflammation, infection, malignancy, tissue necrosis</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Creatinine (serum)</b></td>
                        <td>Creatinine (serum)</td>
                        <td>Enzymatic Method<br />
                           Male: 55-96<br />
                           Female: 40-66<br />

                           Other methods have different reference intervals; refer to local lab reference intervals</td>
                        <td>umol/L</td>
                        <td>Acromegaly, hyperthyroidism, renal impairment (acute or chronic), rhabdomyolysis</td>
                        <td>Amputation, debilitation, quadriplegia or starvation (▼ muscle mass), pregnancy</td>
                     </tr>

                     <tr >
                        <td><b>Creatinine (urine)</b></td>
                        <td>Creatinine (urine)</td>
                        <td>Male: 2.12-3.46<br />
                           Female: 1.41-2.89</td>
                        <td>mmol/L</td>
                        <td>Acromegaly, diabetes mellitus, exercise (▲▲), gigantism, hypothyroidism, infection</td>
                        <td>Amputation, debilitation, quadriplegia or starvation (▼ muscle mass), advanced renal disease, anemia, hyperthyroidism, muscular dystrophy</td>
                     </tr>



                     <tr >
                        <td><b>CK</b></td>
                        <td>Creatine Kinase</td>
                        <td>Males: 20-200<br />
                           Females: 20-180</td>
                        <td>U/L</td>
                        <td>Myopathic disorders (dermatomyositis, myocarditis, polymyositis, rhabdomyolysis), myocardial infarction, surgery, trauma</td>
                        <td>Amputation, debilitation, quadriplegia or starvation (▼ muscle mass), sedentary lifestyle</td>
                     </tr>

                     <tr >
                        <td><b>D-Dimer</b></td>
                        <td>D-Dimer</td>
                        <td>&#60;500</td>
                        <td>ng/mL</td>
                        <td>Arterial thromboembolism, fibrinolysis (primary and secondary), thrombolytic therapy with tissue plasminogen activator (t-PA), thrombosis (eg. DVT, pulmonary embolism)</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Direct Coombs test</b></td>
                        <td>Direct Antiglobulin Test</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Transfusion reaction, lupus, mycoplasma pneumonia, mononucleosis, autoimmune hemolytic anemia, chronic lymphocytic leukemia</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Eosinophils</b></td>
                        <td>Eosinophils</td>
                        <td>0.05-0.25</td>
                        <td>x10<sup>9</sup>&nbsp;cells/L&nbsp;
                        </td>
                        <td>Allergic reaction, autoimmune disease, eczema, leukemia, parasitic infection</td>
                        <td>▲ adrenal steroids</td>
                     </tr>

                     <tr >
                        <td><b>ESR</b></td>
                        <td>Erythrocyte sedimentation rate</td>
                        <td>Male: &#60;15<br />
                           Female: &#60;20</td>
                        <td>mm/hr</td>
                        <td>Infection, inflammation, malignancy, paraproteinemia, tissue necrosis, erythrocytosis, hemoglobinopathy</td>
                        <td>Hypofibrinogenemia, polycythemia vera, sickle cell anemia, spherocytosis,hemoglobinopathy
                           <br />false ▼: sample allowed to stand for excessive period of time before testing</td>
                     </tr>

                     <tr >
                        <td><b>RBC</b></td>
                        <td>Erythrocytes (Red Blood Cells)</td>
                        <td>Male: 4.7-6.2<br />
                           Female: 4.2-5.4</td>
                        <td>x10<sup>12</sup>&nbsp;cells/L</td>
                        <td>Congenital heart disease, COPD, erythrocytosis, hemoglobinopathy, polycythemia vera</td>
                        <td>Anemia, bone marrow failure, cirrhosis, dietary defiency (vitamin B12, iron, folate), hemoglobinopathy, hemorrhage</td>
                     </tr>

                     <tr >
                        <td><b>Estradiol</b></td>
                        <td>Estradiol</td>
                        <td>Male: 37-184<br />
                           Female:<br />
                           Early follicular: 73-551<br />
                           Late follicular: 147-1285<br />
                           Midcycle peak: 551-2753<br />
                           Luteal phase: 110-1652<br />
                           Postmenopausal: &#60;74</td>
                        <td>pmol/L</td>
                        <td>Cirrhosis, estrogen-producing tumors, feminization in children, gynecomastia, hepatic necrosis, hyperthyroidism</td>
                        <td>Failed pregnancy, hypogonadism (primary and secondary), Stein-Leventhal syndrome, Turner's syndrome</td>
                     </tr>
                     <tr>
                        <td><b>Ferritin</b></td>
                        <td>Ferritin</td>
                        <td>Male: 20-250<br />
                           Female: 10-120</td>
                        <td>ug/L</td>
                        <td>Acute leukemia, fasting, hepatocellular disease (alcoholic liver disease, inflammatory hepatic disease), inflammatory disease (osteomyelitis, pulmonary infection, rheumatoid arthritis), iron overload (▲▲)</td>
                        <td>Hemodialysis, Iron deficiency, protein deficiency</td>
                     </tr>

                     <tr >
                        <td><b>Fibrinogen</b></td>
                        <td>Fibrinogen</td>
                        <td>Clauss <br />method: 1.5-4.0<br />
                           Refer to your lab's reference interval</td>
                        <td>g/L</td>
                        <td>Acute phase reactant, oral contraceptive use, post-menopause, pregnancy, smoking</td>
                        <td>Cirrhosis, congenital afibrinogenemia, fibrinolysin treatment, hepatitis</td>
                     </tr>

                     <tr >
                        <td><b>Folate</b></td>
                        <td>Folate</td>
                        <td>serum: 11-36</td>
                        <td>nmol/L</td>
                        <td>Blind loop syndrome, distal small bowel disease, pernicious anemia, vegetarian diet, vitamin B12 deficiency
                           <br />false ▲: massive blood transfusion</td>
                        <td>Alcoholism, folate deficiency, hepatic disease, infantile hyperthyroidism,  malnutrition</td>
                     </tr>
                     <tr >
                        <td><b>FSH</b></td>
                        <td>Follicle-stimulating hormone</td>
                        <td>Male: 1.42-15.4<br />
                           Female:<br />
                           Follicular: 1.37-9.9<br />
                           Ovulatory peak: 6.17-17.2<br />
                           Luteal phase: 1.09-9.2<br />
                           Postmenopausal: 19.3-100.6</td>
                        <td>U/L</td>
                        <td>Castration, Klinefelter's syndrome, ovarian/testicular agenesis, primary gonadal failure, Reifenstein syndrome</td>
                        <td>Anorexia nervosa, anterior pituitary hypofunction, hypothalamic disorder, polycystic ovary disease, pregnancy</td>
                     </tr>







                     <tr >
                        <td><b>GGT</b></td>
                        <td>Gamma glutamyl transferase</td>
                        <td>Male: 8-38<br />
                           Female: 5-27</td>
                        <td>U/L</td>
                        <td>Cholestasis, cirrhosis, hepatic necrosis, hepatic tumor, hepatitis, myocardial infarction, pancreatitis</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Glucagon</b></td>
                        <td>Glucagon</td>
                        <td>20-100</td>
                        <td>ng/L</td>
                        <td>Chronic renal failure, diabetes mellitus, glucagonoma, hyperlipoproteinemia (type 3 & 4), stress (infection, trauma)</td>
                        <td>chronic pancreatitis, cystic fibrosis, idiopathic glucagon deficiency</td>
                     </tr>

                     <tr >
                        <td><b>Glucose</b></td>
                        <td>Glucose (serum)</td>
                        <td>Fasting: 4.1-5.9<br />

                           Diabetes diagnosis if:
                           Fasting ≥7 and 2 hr post-glucose load (75g): ≥11.1</td>
                        <td>mmol/L</td>
                        <td>Diabetes mellitus, endocrine disorders (acromegaly, pheochromocytoma, thyrotoxicosis), pancreatic disease, shock, strenuous exercise</td>
                        <td>Endocrine disorder (Addison's disease, hypothyroidism, hypopituitarism), severe hepatic disorder from poisoning, tumor (adrenal carcinoma, fibrosarcoma, stomach cancer, islet cell)
                           <br />false ▼: excessive time between collection and centrifugation, incorrect sample collection tube (non-glycolytic inhibitor)</td>
                     </tr>


                     <tr >
                        <td><b>Glucose (urine)</b></td>
                        <td>Glucose (urine)</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Cushing's syndrome, diabetes mellitus, major trauma, nephrotoxicosis, thyrotoxicosis</td>
                        <td></td>
                     </tr>







                     <tr >
                        <td><b>GH</b></td>
                        <td>Growth Hormone</td>
                        <td>Male: 0-4<br />
                           Female: 0-18</td>
                        <td>ug/L</td>
                        <td>Acromegaly, ectopic growth hormone secrections, Laron dwarfism, malnutrition, pituitary gigantism</td>
                        <td>Adrenal hyperfunction, hypopituitarism, pituitatry dwarfism</td>
                     </tr>


                     <tr >
                        <td><b>Hematocrit</b></td>
                        <td>Hematocrit</td>
                        <td>Male: 0.39-0.49<br />
                           Female: 0.35-0.45</td>
                        <td>RBC Volume Fraction</td>
                        <td>Extreme physical exercise, hemoconcentration, high altitude, polycythemia</td>
                        <td>Anemia
                           <br />false ▼: recumbent position during sample collection</td>
                     </tr>

                     <tr >
                        <td><b>Hemoglobin</b></td>
                        <td>Hemoglobin</td>
                        <td>Male: 140-180<br />
                           Female: 120-160</td>
                        <td>g/L</td>
                        <td>Congenital heart disease, erythrocytosis, polycythemia vera, severe COPD, severe dehydration</td>
                        <td>Anemia, bone marrow failure, cirrhosis, dietary deficiency, hemoglobinopathy</td>
                     </tr>

                     <tr >
                        <td><b>A1c</b></td>
                        <td>Hemoglobin A1c (glycosylated)</td>
                        <td>Normal: &#60;5.7<br />
                           Prediabetes: 5.7-6.4<br />
                           Diabetes: &#62;6.5</td>
                        <td>% (percentage)</td>
                        <td>Diabetes mellitus
                           <br />false ▲: Anemia, folate deficiency, high-dose aspirin/ASA, iron deficiency, vitamin B12 deficiency</td>
                        <td>False ▼:  Blood transfusion, chronic renal disease, high alcohol intake, inherited hemoglobin disorder, pregnancy</td>
                     </tr>

                     <tr >
                        <td><b>Hemoglobin, urine</b></td>
                        <td>Hemoglobin, urine</td>
                        <td></td>
                        <td></td>
                        <td>Glomerulonephritis, interstitial nephritis, acute tubular necrosisis, renal tumor, renal trauma</td>
                        <td></td>
                     </tr>




                     <tr >
                        <td><b>HDL</b></td>
                        <td>High-density lipoprotein, cholesterol</td>
                        <td>Low: &#60;1.04<br />
                           High: &#8805;1.55</td>
                        <td>mmol/L</td>
                        <td>Familiar hyperalphalipoproteinemia, secondary ▲ (alcoholism, primary biliary cirrhosis, chronic hepatitis)</td>
                        <td>Familial CETP deficiency, familial hypoalphalipoproteinemia, familial LCAT deficiency, secondary ▼ (Cushing's syndrome, hypertriglyceridemia, type 2 diabetes), Tangier disease</td>
                     </tr>

                     <tr >
                        <td><b>hs-CRP</b></td>
                        <td>High-sensitivity C-reactive protein</td>
                        <td>Cardiovascular&nbsp;risk&nbsp;strafication<br />
                           Low risk: &#60;2<br />
                           High risk: &#62;2</td>
                        <td>mg/L</td>
                        <td>Vascular inflammation ▲ cardiovascular disease risk</td>
                        <td></td>
                     </tr>


                     <tr >
                        <td><b>Homocysteine</b></td>
                        <td>Homocysteine</td>
                        <td>Males: 4.4-15<br />
                           Females: 5.5-16.3</td>
                        <td>umol/L</td>
                        <td>Homocystinuria, hypothyroidism, kidney disease, malignancy, nutritional deficiency (folate, vitamin B6, vitamin B12)</td>
                        <td></td>
                     </tr>


                     <tr >
                        <td><b>Insulin</b></td>
                        <td>Insulin</td>
                        <td>Adult (fasting): 5-63<br />
                           Type 2 <br />diabetes: 5-174</td>
                        <td>pmol/L</td>
                        <td> Acromegaly, Cushing's syndrome, hepatic disease, insulinoma, non-insulin dependent (type 2) diabetes mellitus</td>
                        <td>Hypopituitarism, insulin dependent (type 1) diabetes mellitus</td>
                     </tr>

                     <tr >
                        <td><b>Iron</b></td>
                        <td>Iron (Fe)</td>
                        <td>Male: 11.6-31.3<br />
                           Female: 9.0-30.4</td>
                        <td>umol/L</td>
                        <td>Acute hepatitis, acute leukemia, anemia (aplastic, hemolytic, pernicious), hemochromatosis, lead poisoning</td>
                        <td>Hypothyroidism, infection (acute & chronic), iron-deficiency anemia, kwashiorkor, malignancy, nephrosis</td>
                     </tr>


                     <tr >
                        <td><b>Ketones (serum)</b></td>
                        <td>Ketones (serum)</td>
                        <td>&#60;0.1</td>
                        <td>mmol/L</td>
                        <td>Alcoholism, diabetes mellitus, high-protein diets, prolonged vomiting, starvation</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Ketones (urine)</b></td>
                        <td>Ketones (urine)</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Alcoholism, diabetes mellitus, high-protein diets, prolonged vomiting, starvation</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Lactate</b></td>
                        <td>lactate</td>
                        <td>Enzymatic Method<br />
                           Venous: 0.5-2.2<br />
                           Arterial: 0.5-1.6<br />
                           Other methods have different reference intervals; refer to local lab reference intervals</td>
                        <td>mmol/L</td>
                        <td>Glucagon treatment, glycogen storage disease, hyperventilation, lactic acidosis (type I), muscular exercise, severe anemia
                           <br />false ▲: improperly handled sample, tourniquet or hand clenching during sample collection</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>LDH</b></td>
                        <td>Lactate dehydrogenase</td>
                        <td>140-280<br />
                           Reference intervals are method dependent; refer to local lab reference intervals</td>
                        <td>U/L</td>
                        <td>Anemia (megaloblastic, pernicious), carcinomatosis, cirrhosis, hypoxia, shock, viral hepatitis</td>
                        <td>Genetic deficiency of H or M subunits</td>
                     </tr>

                     <tr >
                        <td><b>WBC</b></td>
                        <td>Leukocytes</td>
                        <td>3.5-12</td>
                        <td>x10<sup>9</sup>&nbsp;cells/L
                        </td>
                        <td>Infection, leukemic neoplasia, malignancy, trauma</td>
                        <td>Bone marrow failure, congenital bone marrow aplasia, overwhelming infection, iron deficiency, vitamin B12 deficiency</td>
                     </tr>


                     <tr >
                        <td><b>Lipase</b></td>
                        <td>Lipase</td>
                        <td>Triacylglycerol acyl-hydrolase <br />Method: &#60;3.4<br />

                           1,2-o-dilauryl-rac-glycero-3-glutaric acid-(6'-methylresorufin) <br />method: &#60;1<br />

                           refer to local lab reference intervals</td>
                        <td>uKat/L</td>
                        <td>Lung tumor, pancreatitis
                           <br />false ▲: Hemolysis</td>
                        <td>false ▼: Lipemia</td>
                     </tr>

                     <tr >
                        <td><b>LDL</b></td>
                        <td>Low-density lipoprotein, cholesterol</td>
                        <td>Optimal: &#60;2.85<br />
                           Borderline: 2.85-4.13<br />
                           High: ≥4.14</td>
                        <td>mmol/L</td>
                        <td>Coronary heart disease, defective apoB-100, familial combined hyperlipidemia, familial dysbetalipoproteinemia (type III), Familial hypercholesterolemia, polygenic hypercholesterolemia</td>
                        <td>Abetalipoproteinemia, hypobetalipoproteinemia, secondary causes (hepatocellular dysfunction (hypothrooidism, infection, inflammation, malignancy)</td>
                     </tr>

                     <tr >
                        <td><b>LH</b></td>
                        <td>Luteinizing hormone</td>
                        <td>Male: 1.3-9.6<br />
                           Female:<br />
                           Follicular: 2.4-6.6<br />
                           Mid-cycle peak: 9.1-74.2<br />
                           Luteal phase: 0.9-9.3<br />
                           Postmenopausal: 10.4-64.5</td>
                        <td>U/L</td>
                        <td>Primary ovarian failure (ovarian development failure, ovarian tumor, polycystic ovary syndrome, Turner's syndrome)
                           Primary testicular failure (gonad development failure, Klinefelter's syndrome)</td>
                        <td>Secondary ovarian failure
                           Secondary testicular failure</td>
                     </tr>

                     <tr >
                        <td><b>Lymphocytes</b></td>
                        <td>lymphocytes</td>
                        <td>1.5-3</td>
                        <td>x10<sup>9</sup>&nbsp;cells/mm<sup>3</sup><br />
                           (cells/uL)</td>
                        <td>Chronic bacterial infection, lymphocytic leukemia, multiple myeloma, radiation exposure/treatment, viral infection</td>
                        <td>HIV (late stages), leukemia, lupus erythematosus, immunodeficiency disease, sepsis</td>
                     </tr>

                     <tr >
                        <td><b>Magnesium</b></td>
                        <td>Magnesium (Mg)</td>
                        <td>0.66-1.07</td>
                        <td>mmol/L</td>
                        <td>Addison's disease,  adrenocortical insufficiency, dehydration, renal insufficiency, rhabdomyolysis, uncontrolled diabetes mellitus</td>
                        <td>Acute pancreatitis, chronic alcoholism, hypoparathyroidism, kwashiorkor, malabsorption</td>
                     </tr>

                     <tr >
                        <td><b>MCV</b></td>
                        <td>Mean corpuscular volume</td>
                        <td>76-100</td>
                        <td>fL</td>
                        <td>Alcoholism, antimetabolite therapy, chronic liver disease, folate deficiency, pernicious anemia</td>
                        <td>Anemia of chronic illness, iron deficiency anemia, thalassemia</td>
                     </tr>

                     <tr >
                        <td><b>Methemoglobin</b></td>
                        <td>Methemoglobin</td>
                        <td>&#60;1.5</td>
                        <td>%</td>
                        <td>Cytochrome b5 reductase deficiency, hemoglobin M variant</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Myoglobin (urine)</b></td>
                        <td>Myoglobin (urine)</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Haff disease, rhabdomyolysis, severe electrical shock, skeletal/cardiac muscle injury, thermal burns</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Neutrophils</b></td>
                        <td>Neutrophils (ANC, absolute neutrophil count)</td>
                        <td>1.56-6.45</td>
                        <td>x10<sup>9</sup>&nbsp;cells/L
                        </td>
                        <td>Acute bacterial infection, chronic idiopathic neutrophilia, chronic myeloproliferative disorders, hereditary neutrophilia, non-hematopoietic malignancies</td>
                        <td>Aplastic anemia, benign ethnic neutropenia (BEN), cyclic neutropenia, granulomatosis with polyangiitis, infection (hepatitis, HIV, TB, sepsis), malignancy (leukemia, lymphoma), rheumatoid arthritis</td>
                     </tr>

                     <tr >
                        <td><b>O2 saturation</b></td>
                        <td>O2 saturation (PaO2)</td>
                        <td>&#8805;95 <br /><br />25-29</td>
                        <td>%<br /><br />mm Hg</td>
                        <td></td>
                        <td>Acute respiratory distress syndrome, anemia, asthma, bronchitis, COPD, CHF</td>
                     </tr>




                     <tr >
                        <td><b>FOBT</b></td>
                        <td>Occult blood, fecal</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Diverticular disease, gastritis, GI malignancies, GI polyps, ischemic bowel disease, peptic ulcer, variceal bleeding</td>
                        <td>false ▼: vitamin C (ascorbic acid) intake</td>
                     </tr>

                     <tr >
                        <td><b>Osmolality (serum)</b></td>
                        <td>Osmolality (serum)</td>
                        <td>275-295</td>
                        <td>mOsm/kg</td>
                        <td>Diabetes insipidus, diabetic keotacidosis, hypernatremic ethanol intoxication, hyperosmolar nonketotic hyperglycemia, water depletion</td>
                        <td>Adrenocortical insufficency, panhypopituitarism, post-operative states, SIADH, water intoxication</td>
                     </tr>

                     <tr >
                        <td><b>Osmolality (urine)</b></td>
                        <td>Osmolality (urine)</td>
                        <td>150-1150</td>
                        <td>mOsm/kg</td>
                        <td>Adrenal insufficiency, dehydration, glycosuria, high-protein diet, hypernatremia, SIADH</td>
                        <td>Acute renal insufficiency, diabetes insipidus, excessive fluid intake, glomerulonephritis</td>
                     </tr>

                     <tr >
                        <td><b>PTH</b></td>
                        <td>Parathyroid hormone</td>
                        <td>10-65</td>
                        <td>ng/L</td>
                        <td>Primary hyperparathyroidism, pseudohypoparathyroidism, hereditary secondary hyperparathyroidism, vitamin D dependecy (Type I and II), Zollinger-Ellison syndrome</td>
                        <td>Autoimmune hypoparathyroidism, hypomagnesemia, hyperthyroidism, nonparathyroid hypercalcemia, sarcoidosis, thyroidectomy</td>
                     </tr>

                     <tr >
                        <td><b>pH</b></td>
                        <td>pH (blood)</td>
                        <td>7.35-7.45</td>
                        <td></td>
                        <td>Asthma, CHF, chronic alcohol use, COPD, hepatic failure, malignancy, metabolic alkalosis, pulmonary fibrosis, respiratory alkalosis</td>
                        <td>CHF, hypokalemia, hyperventilation, metabolic acidosis, renal failure, respiratory acidosis, vomiting</td>
                     </tr>

                     <tr >
                        <td><b>Phosphate</b></td>
                        <td>Phosphate (PO4)</td>
                        <td>0.78-1.42</td>
                        <td>mmol/L</td>
                        <td>Milk-alkali syndrome, lymphoma/leukemia (tumor lysis syndrome), osteolytic metastatic bone tumors, sarcoidosis, vitamin D intoxication</td>
                        <td>Acute alcoholism, growth hormone deficiency, osteomalacia, renal tubular acisosis, steatorrhea</td>
                     </tr>

                     <tr >
                        <td><b>Platelets</b></td>
                        <td>Platelets</td>
                        <td>150-400</td>
                        <td>x10<sup>9</sup>&nbsp;cells/L
                        </td>
                        <td>Anemia, leukemia, lymphoma, polycythemia vera, post-splenectormy syndrome, rheumatoid arthritis</td>
                        <td>Graves' disease, hemorrhage, hypersplenism, immune thrombocytopenia (ITP)</td>
                     </tr>

                     <tr >
                        <td><b>Potassium</b></td>
                        <td>Potassium (K)</td>
                        <td>Male: 3.5-4.5<br />
                           Female: 3.4-4.4</td>
                        <td>mmol/L</td>
                        <td>Addison's disease, excessive supplementation (IV fluids), hemolysis, lymphoma/leukemia (tumor lysis syndrome), renal failure with oliguria, tissue damage
                           <br />false ▲: Hemolysis, thrombocythemia</td>
                        <td>Cushing's syndrome, diarrhea, hyperaldosteronism, renal tubular acidosis, vomiting
                           <br />false ▼: delayed sample processing in a warm environment</td>
                     </tr>

                     <tr >
                        <td><b>Prolactin</b></td>
                        <td>Prolactin</td>
                        <td>Male: 3.0-14.7<br />
                           Female: 3.8-23.2</td>
                        <td>mg/L</td>
                        <td>Amenorrhea, galactorrhea, hypothalamic-pituitary diseases (sarcoidosis, granulomatous disease), prolactin-secreting pituitary tumor</td>
                        <td>Sheehan's syndrome</td>
                     </tr>

                     <tr >
                        <td><b>PSA</b></td>
                        <td>Prostate-specific antigen</td>
                        <td>&#60;4.0</td>
                        <td>ug/L</td>
                        <td>Advanced age, BPH, prostate cancer, prostatitis</td>
                        <td></td>
                     </tr>

                     <tr >
                        <td><b>Total protein (serum)</b></td>
                        <td>Protein, serum (total)</td>
                        <td>60-80</td>
                        <td>g/L</td>
                        <td>Acute phase response,hyperimmunoglobulinemia (poly- or monoclonal gammopathy),pseudohyperproteinemia (hemoconcentration, sample desiccation)</td>
                        <td>conditions associated with ▼ protein synthesis, nephrotic syndrome, protein-losing gastroenteropathies</td>
                     </tr>


                     <tr >
                        <td><b>Total Protein (urine)</b></td>
                        <td>Protein (urine)</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Diabetic glomerulosclerosis, glomerulonephritis, malignant hypertension, nephrotic syndrome,polycystic kidney disease,systemic lupus erythematosus</td>
                        <td></td>
                     </tr>


                     <tr >
                        <td><b>INR</b></td>
                        <td>International Normalized Ratio</td>
                        <td>0.8-1.2 (untreated)<br />
                           2.0-3.0 (usual therapeutic range with warfarin treatment)</td>
                        <td></td>
                        <td>Disseminated intravascular coagulation, hepatic disease, hereditary defieciency of clotting factors (II, V, VII and X), malabsorption. vitamin K deficiency</td>
                        <td>▼ antithrombin</td>
                     </tr>

                     <tr >
                        <td><b>RDW</b></td>
                        <td>RBC distribution width</td>
                        <td>11-14.5</td>
                        <td>%</td>
                        <td>Hemoglobinopathies, hemolytic anemia, nutritional deficiency (folate, iron, vitamin B12)</td>
                        <td></td>
                     </tr>



                     <tr >
                        <td><b>RBC (urine)</b></td>
                        <td>Red blood cell (urine)</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Glomerulonephritis, polycystic kidney disease, post-renal disease, tubulointerstitial disease, vasculitis</td>
                        <td></td>
                     </tr>




                     <tr >
                        <td><b>SHBG</b></td>
                        <td>Sex hormone binding globulin</td>
                        <td>Males: 13.3-89.5<br />
                           Females: 18.2-135.5</td>
                        <td>nmol/L</td>
                        <td>Anorexia nervosa, estrogen use, hepatic disease, hyperthryroidism, hypogonadism, pregnancy</td>
                        <td>Cushing's syndrome, hypothyroidism, polycystic ovary syndrome, testicular cancer, type 2 diabetes</td>
                     </tr>



                     <tr >
                        <td><b>Sodium</b></td>
                        <td>Sodium (Na)</td>
                        <td>136-145</td>
                        <td>mmol/L</td>
                        <td>Cushing's syndrome, dehydration, hypoaldosteronism, water loss in excess of salt loss (diarrhea, hyperpnea, polyuria, sweating, vomiting)
                           <br />false ▲: Hypoproteinemia</td>
                        <td>CHF, diabetes mellitus, dilutional hyponatremia with edema, sodium loss with water replacement (diarrhea, hyperpnea, salt-losing nephropathy, sweating, vomiting), SIADH
                           <br />false ▼: Hyperlipidemia, hyperproteinemia, hypertriglyceridemia</td>
                     </tr>



                     <tr >
                        <td><b>Testosterone</b></td>
                        <td>Testosterone</td>
                        <td>Male, Total: 9.72-38.17<br />
                           Male, Free: 0.174-0.729<br />
                           Female, Total: 0.52-2.43<br />
                           Female, Free: 0.0032-0.0295</td>
                        <td>pmol/L</td>
                        <td>Adrenocortical tumors, extragonadal tumors producing gonadotropin (males),idiopathic hirsutism, testicular feminization, trophoblastic disease (pregnancy), virilizing ovarian tumor</td>
                        <td>cryptorchidism, Down syndrome, hepatic insufficiency, hypogonadism (primary and secondary), myotonic dystrophy, uremia</td>
                     </tr>


                     <tr >
                        <td><b>TSH</b></td>
                        <td>Thyroid-stimulating hormone
                           {/* tdyroid-stimulating hormone */}
                        </td>
                        <td>0.4-4.2</td>
                        <td>mU/L</td>
                        <td>

                           Ectopic TSH secretions (lung, breast tumors),Hashimoto's thyroiditis,non-thyroidal illness,primary hypothyroidism,subacute thyroiditis
                           <br />false ▲: TSH autoantibodies (polyclonal assays)
                        </td>
                        <td>
                           Euthyroid sick syndrome,primary hyperthyroidism, secondary hyperthyroidism,subclinical hyperthyroidism
                           <br />false ▼: TSH autoantibodies (monoclonal assays)
                        </td>
                     </tr>


                     <tr >
                        <td><b>Free T4</b></td>
                        <td>Thyroxine, Free</td>
                        <td>10-35</td>
                        <td>pmol/L</td>
                        <td>Excessive thyroxine supplementation, hyperthyroidism</td>
                        <td>exogenous triiodothyronine (T3) use, hypothyroidism</td>
                     </tr>


                     <tr >
                        <td><b>Transferrin</b></td>
                        <td>Transferrin</td>
                        <td>Male: 2.15-3.65<br />
                           Female: 2.50-3.80</td>
                        <td>g/L</td>
                        <td>Estrogen exposure (oral contraceptive use, pregnancy), iron deficiency</td>
                        <td>Inherited atransferrinemia, inflammation, malnutrition, necrosis, nephrotic syndrome</td>
                     </tr>


                     <tr >
                        <td><b>Triglycerides</b></td>
                        <td>Triglycerides</td>
                        <td>Normal: &#60;1.70<br />
                           Borderline: 1.7-2.25<br />
                           High: ≥2.26</td>
                        <td>mmol/L</td>
                        <td>Familial combined hyperlipidemia, familial dysbetalipoproteinemia, familial hypertriglyceridemia, severe hypertriglyceridemia (Type V), pancreatitis</td>
                        <td>Abetalipoporteinemia, hypobetalipoporteinemia, malabsorption, malnutrition</td>
                     </tr>


                     <tr >
                        <td><b>Free T3</b></td>
                        <td>Triiodothyronine, Free</td>
                        <td>4.0-7.4</td>
                        <td>pmol/L</td>
                        <td>Hyperthyroidism, triiodothyronine toxicosis</td>
                        <td>Hypothyroidism, pregnancy (3rd trimester)</td>
                     </tr>


                     <tr >
                        <td><b>Urea</b></td>
                        <td>Urea (BUN)</td>
                        <td>2.1-7.1</td>
                        <td>mmol/L</td>
                        <td>Renal impairment (CHF, post-renal obstruction, water depletion)</td>
                        <td>▲ protein utilization (acromegaly, infancy, pregnancy, ▼ synthesis)
                           {/* ▲ protein utilization (acromegaly, infancy, pregnancy, syntdesis) */}

                        </td>
                     </tr>
                     <tr >
                        <td><b>Uric Acid</b></td>
                        <td>Uric Acid</td>
                        <td>Male: 208-428<br />
                           Female: 155-357</td>
                        <td>umol/L</td>
                        <td>polycythemia
                           {/* Gout, lymphoma/leukemia (tumor lysis syndrome), multiple myeloma, polycythemia, renal failure */}
                        </td>
                        <td>Fanconi's syndrome, malignancy, SIADH, Wilson's disease</td>
                     </tr>
                     <tr >
                        <td><b>Vitamin A</b></td>
                        <td>Vitamin A</td>
                        <td>4.0-7.4</td>
                        <td>umol/L</td>
                        <td>hypervitaminosis A
                           {/* Chronic kidney disease, Hypervitaminosis A, toxicity, oral contraceptive use */}
                        </td>
                        <td>hypothyroidism
                           {/* GI disease, hepatic disease, hypothyroidism, pancreatic disease */}
                        </td>
                     </tr>

                     <tr >
                        <td><b>Vitamin B12</b></td>
                        <td>Vitamin B12</td>
                        <td>185-812</td>
                        <td>pmol/L</td>
                        <td>Chronic renal failure, CHF, diabetes mellitus, leukemia, ▲ transcobalamin</td>
                        <td>Congenital disorder, intrinisic factor deficiency, malabsorption, vitamin B12 deficiency</td>
                     </tr>





                     <tr >
                        <td><b>Vitamin D</b></td>
                        <td>Vitamin D</td>
                        <td>35-150</td>
                        <td>nmol/L</td>
                        <td>Excessive sunlight exposure, intoxication</td>
                        <td>Celiac disease, cirrhosis, dietary osteomalcia, IBD, malabsorption, steatorrhea, rickets</td>
                     </tr>




                     <tr >
                        <td><b>Vitamin E</b></td>
                        <td>Vitamin E</td>
                        <td>12-42</td>
                        <td>umol/L</td>
                        <td>Hyperlipidemia</td>
                        <td>Abetalipoproteinemia, malabsoprtion</td>
                     </tr>



                     <tr >
                        <td><b>WBC (urine)</b></td>
                        <td>White blood cells (urine)</td>
                        <td>Negative</td>
                        <td></td>
                        <td>Urinary tract infection</td>
                        <td></td>
                     </tr>


                  </tbody>
               </Table>
            </div>
            <br />
            <Table>
               <div className="container">
                  <h6 style={{ fontSize: 14 }}><b>Table by Dr. Joshua Buse, PhD, DABCC</b></h6>
                  <br />
                  <p style={{ fontWeight: '600px', fontSize: 14, color: '#484343' }}><b>References:</b><br />
                     <a target={"_blank"} href=" https://www.mayocliniclabs.com/test-catalog">
                        Mayo Foundation for Medical Education and Research (1995-2022) Test Catalog.</a><br />
                     Pagana, K. D., Pagana, T. J., & Pike-MacDonald, S. A. (2018). Mosby's Canadian Manual of Diagnostic and Laboratory Tests. Mosby.<br />
                     Rifai, N., Horvath, A. R., Wittwer, C., & Tietz, N. W. (2018). Tietz textbook of clinical chemistry and molecular diagnostics. St. Louis, Mo: Elsevier<br />
                     Sadrazeh, H., Kline, G. (2018) Endocrine Biomarkers Clinicians and Clinical Chemists in Partnership. St. Louis, Mo: Elsevier<br />
                     <a target={"_blank"} href=" https://www.testing.com/tests/">
                        Testing.com (2022) Tests.</a>
                     <br />
                     Wallach, J. B. (2007). Interpretation of diagnostic tests. Philadelphia: Wolters Kluwer.
                     <br />
                     Wu, A. H. B. (2006). Tietz clinical guide to laboratory tests. St. Louis, Mo: Saunders/Elsevier.
                  </p>
               </div>
            </Table>

         </div>
      </div>
   );

}

export default LabvaluesCanada;