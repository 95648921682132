import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Table from 'react-bootstrap/Table';


const Canadainsuline = () => {
        
        $(document).ready(function () {
                $(".search").keyup(function () {
                        var searchTerm = $(".search").val();
                        if (searchTerm == "") {
                                window.location.reload();
                        }
                        var listItem = $('.results tbody').children('tr');
                        var searchSplit = searchTerm.replace(/ /g, "'):containsi('")

                        $.extend($.expr[':'], {
                                'containsi': function (elem, i, match, array) {
                                        return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
                                }
                        });

                        $(".results tbody tr").not(":containsi('" + searchSplit + "')").each(function (e) {
                                $(this).attr('visible', 'false');
                        });

                        $(".results tbody tr:containsi('" + searchSplit + "')").each(function (e) {
                                $(this).attr('visible', 'true');
                        });

                        var jobCount = $('.results tbody tr[visible="true"]').length;
                        $('.counter').text(jobCount + ' item');

                        if (jobCount == '0') { $('.no-result').show(); }
                        else {
                                $('.no-result').hide();
                                $(this).attr('visible', 'false');
                        }
                });
        });



        return (
                <div style={{ textAlign: 'initial' }}>
                        {/* <PageHeaderTable style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                                title="Insulin Products Table (Canada)"
                                description={""} /> */}
                        <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                                title="Insulin Products Table (Canada)" />

                        <div className="container mt-1" >
                                <br />
                                {/* <div className="row">
                                        <div className="col-md-4 input-group">
                                                <button id='static-search'><i className="fa fa-search" aria-hidden="true"></i></button> <input type='text' placeholder="Search.." onChange={myFunction} id="myInput" />
                                        </div>
                                </div> */}
                                <div className="form-group pull-left">
                                        <input type="text" className="search form-control" placeholder="Search...." />
                                </div>



                                <Table striped bordered hover id="table_insulin" className="results" style={{ width: "100%" }}>

                                        <thead className="header" >
                                                <tr style={{ height: "40px" }}>
                                                        <td style={{ width: "40%", textAlign: 'center' }}><b>Insulin</b></td>
                                                        <td style={{ width: "15%", textAlign: 'center' }}><b>Brand</b></td>
                                                        <td style={{ width: "15%", textAlign: 'center' }}><b>Onset</b></td>
                                                        <td style={{ width: "10%", textAlign: 'center' }}><b>Peak Effect</b></td>
                                                        <td style={{ width: "10%", textAlign: 'center' }}><b>Duration</b></td>
                                                        <td style={{ width: "100%", textAlign: 'center' }}><b>Analog/Biosimilar</b></td>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <tr>
                                                        <td><b>&nbsp;Rapid-acting/bolus&nbsp;insulin</b></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                </tr>
                                                <tr style={{ borderBottom: 0 }}>
                                                        <td id="whiteBorder">&nbsp;insulin&nbsp;aspart</td>
                                                        {/* <td>&nbsp;insulin&nbsp;aspart</td> */}
                                                        <td >&nbsp;Fiasp</td>
                                                        <td>&nbsp;16-22 min</td>
                                                        <td> &nbsp;1.5-2.2 hr</td>
                                                        <td>&nbsp;5-7 hr</td>
                                                        <td > &nbsp;analog†</td>
                                                </tr>
                                                <tr>
                                                        {/* <td></td> */}
                                                        <td style={{ backgroundColor: "white", color: "white" }} id="whiteBorder">insulin aspart</td>
                                                        <td style={{ backgroundColor: "white" }}>&nbsp;Novorapid</td>
                                                        <td style={{ backgroundColor: "white" }}>&nbsp;10-20min</td>
                                                        <td style={{ backgroundColor: "white" }}> &nbsp;1-3&nbsp;hr</td>
                                                        <td style={{ backgroundColor: "white" }}>&nbsp;3-5&nbsp;hr</td>
                                                        <td style={{ backgroundColor: "white", borderLeft: 0 }}></td>
                                                </tr>

                                                <tr >
                                                        {/* <td></td> */}
                                                        <td style={{ color: "white" }} id="whiteBorder">insulin aspart</td>
                                                        <td>&nbsp;Trurapi</td>
                                                        <td>&nbsp;10-20min</td>
                                                        <td> &nbsp;1-3&nbsp;hr</td>
                                                        <td>&nbsp;3-5&nbsp;hr</td>
                                                        <td>&nbsp;biosimilar</td>
                                                </tr>

                                                <tr >
                                                        <td>&nbsp;insulin&nbsp;glulisine</td>
                                                        <td>&nbsp;Apidra</td>
                                                        <td>&nbsp; 12-30 min</td>
                                                        <td> &nbsp;1.6-2.8hr</td>
                                                        <td>&nbsp;3-4hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >

                                                        <td id="whiteBorderGray">&nbsp;insulin lispro</td>

                                                        <td>&nbsp;Admelog</td>
                                                        <td>&nbsp;30-45 min</td>
                                                        <td> &nbsp;2.1 hr</td>
                                                        <td>&nbsp;6.9 hr</td>
                                                        <td>&nbsp;biosimilar</td>
                                                </tr>
                                                <tr >
                                                        <td style={{ color: "white" }} id="whiteBorderGray">insulin lispro</td>
                                                        <td >&nbsp;Humalog</td>
                                                        <td>&nbsp;30-45 min</td>
                                                        <td> &nbsp;0.75-2.5 hr</td>
                                                        <td>&nbsp;3.5-4.75 hr</td>
                                                        <td></td>
                                                </tr>

                                                <tr>
                                                        <td> <b>&nbsp;Rapid-acting/bolus&nbsp;insulin</b></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                </tr>

                                                {/* 
                                        <tr >
                                                <td>&nbsp;Lyumjev</td>
                                                <td>&nbsp;15-30 min</td>
                                                <td> &nbsp;2-2.9 hr</td>
                                                <td>&nbsp;4.6-7.3 hr</td>
                                                <td>&nbsp;biosimilar</td>
                                        </tr>
                                        <tr >
                                                <td>
                                                        &nbsp;insulin inhalation</td>
                                                <td>&nbsp;Afrezza</td>
                                                <td>&nbsp;14 min</td>
                                                <td> &nbsp;35-55 min</td>
                                                <td>&nbsp;1.5-4.5 hr</td>
                                                <td></td>
                                        </tr> */}

                                                {/* <tr>
                                                {bools == false ? <> <td>&nbsp;<><b>Short-acting/bolus insulin</b></></td></> : ''}
                                        </tr> */}
                                                <tr>
                                                        <td id="whiteBorderGray">&nbsp;Insulin regular</td>

                                                        <td>&nbsp;Humulin R</td>
                                                        <td>&nbsp;30 min*</td>
                                                        <td> &nbsp;1.5-3.5 hr*</td>
                                                        <td>&nbsp;8 hr*</td>
                                                        <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">Insulin regular</td>
                                                        <td>&nbsp;Entuzity</td>
                                                        <td>&nbsp;15 min*</td>
                                                        <td> &nbsp;4-8 hr</td>
                                                        <td>&nbsp;13-24 hrΔ</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">Insulin regular</td>
                                                        <td>&nbsp;Hypurin Regular</td>
                                                        <td>&nbsp;30-60 min</td>
                                                        <td> &nbsp;3-5 hr</td>
                                                        <td>&nbsp;6-8 hr</td>
                                                        <td>&nbsp;porcine source</td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">Insulin regular</td>
                                                        <td>&nbsp;Novolin ge Toronto</td>
                                                        <td>&nbsp;30 min*</td>
                                                        <td> &nbsp;1.5-3.5 hr*</td>
                                                        <td>&nbsp;8 hr*</td>
                                                        <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                        <b>&nbsp;Intermediate-acting&nbsp;insulin</b>
                                                </tr>



                                                <tr>
                                                        <td id="whiteBorderGray">&nbsp;Insulin NPH</td>

                                                        <td>&nbsp;Humulin N</td>
                                                        <td>&nbsp;1-2&nbsp;hr</td>
                                                        <td> &nbsp;4-12 hr</td>
                                                        <td>&nbsp;14-24 hr</td>
                                                        <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">Insulin NPH</td>
                                                        <td>&nbsp;Hypurin NPH</td>
                                                        <td>&nbsp;1-2 hr</td>
                                                        <td> &nbsp;6-12 hr</td>
                                                        <td>&nbsp;18-24 hr</td>
                                                        <td>&nbsp;porcine source</td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">Insulin NPH</td>
                                                        <td>&nbsp;Novolin ge NPH </td>
                                                        <td>&nbsp;1.5 hr</td>
                                                        <td> &nbsp;4-12 hr</td>
                                                        <td>&nbsp;up to 24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <b>&nbsp;Long-acting/basal&nbsp;insulin</b>
                                                </tr>
                                                <tr>
                                                        <td>&nbsp;insulin degludec</td>
                                                        <td>&nbsp;Tresiba</td>
                                                        <td> &nbsp;1 hr</td>
                                                        <td>&nbsp;9-12 hr</td>
                                                        <td>&nbsp;42 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <td>&nbsp;insulin detemir</td>
                                                        <td>&nbsp;Levemir</td>
                                                        <td> &nbsp;3-4 hr</td>
                                                        <td>&nbsp;3-9 hr</td>
                                                        <td>&nbsp;6-23 hr*</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <td id="whiteBorderGray">&nbsp;insulin glargine</td>
                                                        <td>&nbsp;Basaglar</td>
                                                        <td>&nbsp;3-4&nbsp;hr</td>
                                                        <td> &nbsp;no peak</td>
                                                        <td>&nbsp;24+ hr</td>
                                                        <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">&nbsp;insulin icodec</td>
                                                        <td>&nbsp;Lantus</td>
                                                        <td>&nbsp;3-4 hr</td>
                                                        <td> &nbsp;no peak</td>
                                                        <td>&nbsp;24+ hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">insulin glargine</td>
                                                        <td>&nbsp;Toujeo</td>
                                                        <td>&nbsp;6 hr</td>
                                                        <td> &nbsp;no peak</td>
                                                        <td>&nbsp;24+ hr</td>
                                                        <td></td>
                                                </tr>
                                                {/*  <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">insulin glargine</td>
                                                        <td>&nbsp;Awiqli</td>
                                                        <td>&nbsp;</td>
                                                        <td> &nbsp;15-18 hr</td>
                                                        <td>&nbsp;1 week</td>
                                                        <td></td>
                                                </tr> */}

                                                <tr>

                                                        <td >&nbsp;insulin icodec</td>
                                                        <td>&nbsp;Awiqli</td>
                                                        <td> &nbsp;</td>
                                                        <td>&nbsp;15-18 hr</td>
                                                        <td>&nbsp;1 week</td>
                                                        <td>&nbsp;</td>
                                                </tr>
                                                {/* <tr>
                                                <td>&nbsp;Rezvoglar</td>
                                                <td>&nbsp;3-4 hr</td>
                                                <td> &nbsp;no peak</td>
                                                <td>&nbsp;24+ hr</td>
                                                <td>&nbsp;biosimilar</td>
                                        </tr>
                                        <tr>
                                                <td>&nbsp;Semglee</td>
                                                <td>&nbsp;3-4 hr</td>
                                                <td> &nbsp;no peak</td>
                                                <td>&nbsp;24+ hr</td>
                                                <td>&nbsp;biosimilar#</td>
                                        </tr> */}
                                                <tr>
                                                        <b>&nbsp;Pre-mixed&nbsp;insulin</b>
                                                </tr>
                                                <tr>
                                                        <td >&nbsp;biphasic insulin &nbsp;aspart</td>
                                                        <td>&nbsp;Novomix 30</td>
                                                        <td> &nbsp;10-20 min</td>
                                                        <td>&nbsp;1-4 hr</td>
                                                        <td>&nbsp;24 hr</td>
                                                        <td>&nbsp;</td>
                                                </tr>




                                                <tr>
                                                        <td id="whiteBorderGray">&nbsp;biphasic insulin&nbsp;lispro</td>

                                                        <td>&nbsp;Humalog&nbsp;Mix&nbsp;25&nbsp;</td>
                                                        <td>&nbsp;15-30 min</td>
                                                        <td> &nbsp;0.5-4 hr</td>
                                                        <td>&nbsp;14-24 hr</td>
                                                        <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">&nbsp;biphasic insulin&nbsp;lispro</td>
                                                        <td>&nbsp;Humalog&nbsp;Mix&nbsp;50</td>
                                                        <td>&nbsp;15-30 min</td>
                                                        <td> &nbsp;0.75-13.5 hr</td>
                                                        <td>&nbsp;14-24 hr</td>
                                                        <td></td>
                                                </tr>

                                                <tr>
                                                        <td id="whiteBorder" >&nbsp;biphasic insulin&nbsp;
                                                                NPH/insulin&nbsp;
                                                                regular</td>

                                                        <td>&nbsp;Novolin ge 30/70 </td>
                                                        <td>&nbsp;30 min</td>
                                                        <td> &nbsp;2-12 hr</td>
                                                        <td>&nbsp;18-24 hr</td>
                                                        <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">&nbsp;biphasic insulin&nbsp;
                                                                NPH/insulin&nbsp;
                                                                regular</td>
                                                        <td>&nbsp;Novolin ge 40/60 </td>
                                                        <td>&nbsp;30 min</td>
                                                        <td> &nbsp;2-8 hr</td>
                                                        <td>&nbsp;up to 24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <td style={{ color: "white" }} id="whiteBorderGray">&nbsp;biphasic insulin&nbsp;
                                                                NPH/insulin&nbsp;
                                                                regular</td>
                                                        <td>&nbsp;Novolin ge 50/50 </td>
                                                        <td>&nbsp;30 min</td>
                                                        <td> &nbsp;2-8 hr</td>
                                                        <td>&nbsp;up to 24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <b>&nbsp;Basal&nbsp;insulin/GLP-1&nbsp;agonist &nbsp;combination &nbsp;products</b>


                                                </tr>
                                                <tr>
                                                        <td>&nbsp;insulin degludec/liraglutide</td>
                                                        <td>&nbsp;Xultophy</td>
                                                        <td colSpan={3}> &nbsp;refer to individual agents</td>
                                                        <td></td>
                                                </tr>
                                                <tr>
                                                        <td>&nbsp;insulin glargine/lixisenatide</td>
                                                        <td>&nbsp;Soliqua</td>
                                                        <td colSpan={3}> &nbsp;refer to individual agents</td>
                                                        <td></td>
                                                </tr>
                                        </tbody>
                                </Table>
                        </div><br />

                        <Table>  <div className="container">
                                <h6 style={{ fontSize: 14 }}><b><u>Footnotes:</u></b></h6>
                                <br />
                                † onset/peak effect occurs earlier in comparative studies<br />
                                * with subcutaneous administration<br />
                                Δ dose-dependent
                                <br />
                                As with all insulin therapies, the duration of action may vary in different individuals or in the same
                                individual according to dose, injection site, blood flow, temperature and level of physical activity.
                                <br />
                                <b>References:</b><br />
                                UpToDate. Accessed December 21, 2021.<br />
                                Respective Canadian Product Monographs
                        </div>
                        </Table>
                </div>
        );

}

export default Canadainsuline;